@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Yeseva+One&display=swap');

body
{
    /*font-family: 'Helvetica', 'Arial', sans-serif;
    color: #444444;
    font-size: 9pt;
    background-color: #FAFAFA;*/
    font-family: 'Quicksand', sans-serif !important;
}

.uk-container {
  max-width: 1400px !important;
}

.setMinContainerHeight {
    min-height: 62vh !important;
}

.App {
  text-align: center;
}

.loginRegisterBtn {
    margin-right: 30px;
}

#email-label {
    color: white;
}

#password-label {
    color: white;
}

.card {
  border: none;
  border-radius: 25px;
}

.c-details span {
  font-weight: 200;
  font-size: 13px;
}

.badge span {
  background-color: #fffbec;
  width: 80px;
  height: 30px;
  padding-bottom: 5px;
  border-radius: 5px;
  display: flex;
  color: #ff3c00;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 1px solid;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 50 50'%3E%3Cpath d='M28.43 6.378C18.27 4.586 8.58 11.37 6.788 21.533c-1.791 10.161 4.994 19.851 15.155 21.643l.707-4.006C14.7 37.768 9.392 30.189 10.794 22.24c1.401-7.95 8.981-13.258 16.93-11.856l.707-4.006z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E")
    center / 50px no-repeat;
}

.heading {
    margin: 10px 0 5px;
    text-transform: capitalize;
    font-weight: 300;
    font-size: 20px;
}

.closeBtn {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 20px;
}

.closeBtn:hover {
    cursor: pointer;
}

/** styles prejsnja **/

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


:root {
    --orange-color: rgb(189, 146, 93);
    --gray-color: #d8d8d8;
    --darker-gray-color: #bfbfbf;
    --dark-gray-text-color: #5a5959;
    --dark-gray-color: #181818;
    --less-orange-color: rgb(248, 242, 226);
    --blue-color: rgb(97, 97, 113);
}

footer {
    font-family: "Avenir";
}

.second_font {
    font-family:  "Avenir";
}

.sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

a:hover {
    text-decoration: underline !important;
}

.margin_top_small {
    margin-top: 15px !important;
}

.margin_top_medium {
    margin-top: 20px !important;
}

.margin_top_large {
    margin-top: 40px !important;
}

.margin_left_large {
  margin-left: 100px !important;
}

.title_nav_link {
    text-decoration: none !important;
}

.title_nav_link:hover {
    text-decoration: none !important;
}

.title_nav_link:focus {
    text-decoration: none !important;
}

.no_padding {
    padding: 0 !important;
}

#logo_img {
    position: absolute;
    margin-left: 30px;
    width: 120px;
    height: 120px;
    top: 15px;
}

#sound_title_img {
    position: absolute;
    right: 80px;
    width: 120px;
    height: 50px;
    top: 21px;
}

.black_line {

    padding-left: 10px;
  /*background-color: black;*/
  background-color: white;
  border: 0px;
  width: 100%;
  height: 75px;
  padding-top: 15px;
}

.darker_line {
    background-color: #324A58;
    border: 0px;
    width: 100%;
    height: 25px;
    padding-left: 0px;
    padding-top: 10px;
    margin-bottom: 100px !important;
}

.gray_line {
    background-color: var(--gray-color);
    border: 0px;
    width: 100%;
    height: 2px;
}

.larger_gray_line {
    background-color: var(--darker-gray-color);
    border: 0px;
    width: 100%;
    height: 7px;
}

.nav {
  margin-top: 4px !important;
}

/*#right_side_nav {
    margin-left: 2px;
}

@media (min-width: 959px) {

    #right_side_nav {
        margin-top: 0px !important;
    }
}*/

#nav_search_bar {
    width: 60% !important;
    max-width: 150px !important;
    margin-left: 10% !important;
}

.storySquereOuter {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    position: relative;
    height: 770px;
}

@media (min-width: 960px) {

    .storySquereOuter {
        height: 730px;
    }
}

.storySquereInner {
    background-color: rgba(232, 189, 80, 0.5) !important;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 10px;
}

.playStoryIncicatorDown {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    height: 30px;
    /* background-color: rgba(220,220,220,0.4) !important; */
    background-color: rgba(0,0,0) !important;
    border-width: 1px;
    border-color: rgba(220,220,220,0.3);
    border-style: solid;
    border-radius: 10px;
    margin-top: 20px;
}

#playStoryIncicatorUp {
    width: 0%;
    height: 28px;
    background-color: rgba(220,220,220,0.4) !important;
    border-radius: 9px;
}

.search_input {
    /*border: 1px solid white !important;*/
    background-color: white !important;
    
    height: 25px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    color:  #324A58 !important;
}

.shadow_bottom {
    box-shadow: 0px 3px 3px 2px #a1a1a1 !important;
}

.cover_photo_text {
    font-size: 1.5rem;
    margin-bottom: 10px !important;
}

.uk-dotnav > .uk-active > * {
    background-color: var(--orange-color) !important;
    border-color: black !important;
}

.uk-dotnav > * > * {
    border-color: black !important;
}

.uk-dotnav > * > :hover {
    background-color: white !important;
}

#overlay_pict_1 {
    width: 300px;
}

.free_story_box {
    border: 2px solid var(--gray-color);
    margin: 50px 20px 50px 20px;
    border-radius: 20px;
    box-shadow: 2px 3px 3px 2px #a1a1a1 !important;
}

    .free_story_box:hover {
        cursor: pointer;
        opacity: 0.7;
    }

.free_story_photo {
    position: relative;
    /*background-image: url(assets/zajec_in_zelva.jpeg);*/
    background-position: center;
    background-size: cover;
    min-height: 110px;
    max-height: 126px;
    width: 100%;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
}

.free_play_gumb_story {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    height: 70px;
    height: 70px;
}

@media (max-width: 1200px) {
    .free_story_box {
        margin: 50px 15px 50px 15px;
    }
}

@media (min-width: 639px) {
    .free_story_box {
        max-height: 130px;
    }

    .free_story_photo {
        height: 100%;
        max-height: 126px;
        width: 80%;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .free_play_gumb_story {
        right: -35px;
        left: unset;
    }
}

.sound_free_play {
    margin-top: 20px;
    height: 76px;
}

.free_story_text {
    margin-left: 20%;
    margin-top: 10px;
    margin-bottom: 0px !important;
    color: var(--orange-color) !important;
    font-size: 16px;
}

#free_story_title_text {
    margin-left: 20%;
    margin-top: 5px;
    margin-bottom: 0px !important;
    color: black !important;
    font-size: 25px
}

#free_story_autor_text {
    margin-left: 20%;
    margin-top: 5px;
    margin-bottom: 0px !important;
    color: black !important;
    font-size: 10px
}

.shadow_inside {
    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
}

.story_container_description {
    margin-top: 20px;
    font-size: 25px;
    /*color: white !important;*/
    color: #324A58 !important;
    text-align: center;
    font-weight: 600;
}

.my-container-home-img {
    max-width: 1500px !important;
    padding-left: 0px !important; 
    padding-right: 0px !important;
}

@media (min-width: 960px) {
    .my-container-home-img {
        padding-left: 30px;
        padding-right: 30px;
    }

    .story_container_description {
        margin-top: 60px;
    }

    #nav_search_bar {
        margin-top: 5px;
    }
}

.google_sign_in_button {
    margin-top: 10px !important;
}

.google-icon {
    margin-right: 10px;
}

.login_screen_container {
    /*margin-left: 25%;
    margin-right: 25%;*/
    margin-top: 50px;
    margin-bottom: 20vh;
    min-height: 40vh;
}

.margin_login_elements {
    margin-top: 10px !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
}


.margin_login_box_top {
    margin-top: 10px !important;
}

.login_text_input {
    color: black !important;
}

.no_margin {
    margin: 0 !important;
}

.subscribe_btn_text {
    margin-top: 5px;
    color: #324A58 !important;
}

.cancel_modal_btn_text {
    margin-top: 5px;
    color: white !important;
}

.subscribe_button {
    width: 45%;
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.confirm_button {
    margin-left: 20px;
    width: 35%;
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.subscribe_button:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.confirm_button:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.cancel_modal_button {
    margin-top: 10px;
    width: 45%;
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.cancel_modal_button:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.cancel_confirm_modal_button {
    width: 35%;
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.cancel_confirm_modal_button:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.modal_heading {
    margin-bottom: 8%;
    color: white !important;
}

.subscribeModal {
    /*background: #1861ac !important;*/
    /*background-image: url(assets/modalec.png) !important;*/
    background-color: #4C6472 !important;
    border: 0px !important;
    border-radius: 10px;
    background-size: cover;
    text-align: center;
    color: white;
}

.margin_top_grid {
    margin-top: 0px !important;
}

.login_box_title {
    padding-top: 7px;
    padding-bottom: 7px;
}

.padding_title_gumb_login {
    padding-top: 7px;
    /*padding-bottom: 7px;*/
}

.login_button:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.6);
}

.margin_top_login_button {
    margin-top: 30px !important;
}

.login_box {
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.7);
}

.margin_login_box {
    margin-left: 20% !important;
    margin-right: 20% !important;
}

.login_box_all {
    text-align: center;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    padding-bottom: 10px !important;
}

.login_box_text {
    color: black;
    text-align: center;
}

.registration_box_text {
    color: black;
    text-align: center;
}

.registration_box_text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.story_container {
  height: 280px;
  position: relative;
  border-radius: 10px;
  background-color: rgba(232, 189, 80, 0.5);
  margin-left: 30px;
  margin-right: 30px;
}

.main_list_container {
    position: relative;
    background-color: rgba(232, 189, 80, 0.5);
    margin-left: 30px;
    margin-right: 30px;
    padding: 10px;
    border-radius: 10px;
}

.main_list_container_mobile {
    position: relative;
    background-color: rgba(232, 189, 80, 0.5);
    padding: 10px;
    border-radius: 10px;
}

.main_list_container:hover {
    cursor: pointer;
    opacity: 0.7;
}

.myGridPravljica {
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.others_list_container {
    width: 80%;
    margin-left: 10% !important;
    position: relative;
    background-color: rgba(232, 189, 80, 0.5);
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
    padding-bottom: 4px;
    padding: 10px;
    border-radius: 5px;
}

.others_list_alert {
    width: 80%;
    margin-left: 10% !important;
    position: relative;
    background-color: rgba(232, 189, 80, 0.5);
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
    padding-bottom: 4px;
    padding: 10px;
    border-radius: 5px;
}

.others_list_container_mobile {
    position: relative;
    background-color: rgba(232, 189, 80, 0.5);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.others_list_container:hover {
    cursor: pointer;
    opacity: 0.7;
}

.storyCard {
  background-color: white;
}

.currentlyPlaying {
    /*color: white;*/
    color: #324A58 !important;
    margin-bottom: 0px;
    margin-left: 17%;
    font-weight: 900;
    font-size: 25px;
}

.othersFromLibrary {
    /*color: white;*/
    color: #324A58 !important;
    margin-bottom: 0px;
    margin-left: 17%;
    font-weight: 900;
    font-size: 20px;
}

.paddles {
}

.paddle {
    position: absolute;
    top: 100px;
    bottom: 90px;
    width: 30px;
    border: none;
    border-radius: 5px;
    opacity: 0.8;
}

.paddle:hover {
    cursor: pointer;
    opacity: 0.5;
}

.left-paddle {
    left: -40px;
}

.right-paddle {
    right: -40px;
}

.hidden {
    display: none;
}

.story_square {
  width: 250px;
  /* height: 230px; */
  margin: 10px 10px 10px 10px;
  border-radius: 10px;
  /* box-shadow: 2px 3px 3px 2px #a1a1a1 !important; */
  display: inline-block;
  /* color: white; */
  text-decoration: none;
  /* background-color: white;*/
}

.story_square:hover {
    opacity: 0.7;
    cursor: pointer;
}

.category_story_square {
    border: 2px solid var(--orange-color);
    border-radius: 20px;
    width: 100%;
    box-shadow: 2px 3px 3px 2px #a1a1a1 !important;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding-left: 0px !important;
}

.category_square_height {
    height: 250px;
}

.story_square_outline:hover a {
    opacity: 0.7 !important;
}

.story_category_photo {
    position: relative;
    width: 100%;
    max-height: 70%;
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
}

.category_container {
    padding-left: 40px;
    padding-right: 20px;
}

.story_square_outline {
    padding-left: 40px !important;
    padding-right: 20px !important;
}

.stories_square_outline {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 50px;
}

.story_list_photo {
    position: relative;
    width: 100%;
    height: 220px;
    background-position: center;
    background-size: cover;
    margin-bottom: 0px !important;
    margin-top: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.play_story_photo {
    position: relative;
    width: 100%;
    height: 700px;
    background-position: center;
    background-size: cover;
    margin-bottom: 0px !important;
    margin-top: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.play_story_photo:hover {
    opacity: 0.7;
    cursor: pointer;
}

.play_story_poslusate {
    /*color: white;*/
    color: #324A58;
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.play_story_title_play {
    /*color: white;*/
    color: #324A58;
    font-size: 35px;
    margin-bottom: 0px;
}

.play_story_title_play {
    /*color: white;*/
    color: #324A58;
    font-size: 35px;
    font-weight: 1000;
}

.play_story_author_play {
    /*color: white;*/
    color: #324A58;
    font-size: 20px;
    margin-bottom: 0px;
}

.play_story_title {
    /*color: white;*/
    color: #324A58;
    font-size: 35px;
    font-weight: 1000;
    margin-top: 40px;
}

.story_list_photo:hover {
    opacity: 0.7;
    cursor: pointer;
}

.story_length_text {
    color: var(--orange-color);
    font-size: 17px;
    margin-left: 20px;
    position: absolute;
    bottom: 10px;
}

.story_list_description_square {
    margin-top: 20px;
    position: relative;
    padding-bottom: 20px;
}

.starButton {
    position: absolute;
    right: 80px;
    bottom: 10px;
    width: 40px;
    height: 40px;
}

.hearthButton {
    position: absolute;
    right: 30px;
    bottom: 10px;
    width: 40px;
    height: 40px;
}

.max_width_categories {
    max-width: 1100px !important;
}

.categories_container {
    min-height: 62vh !important;
}

.story_summary {
    margin-top: 20px;
    display: table;
    color: var(--dark-gray-text-color);
    margin-left: 20px;
    font-size: 17px;
    margin-bottom: 50px;
}

.story_text {
    margin-top: 20px;
    display: table;
    /*color: white;*/
    color: #324A58;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px !important;
    font-size: 18px;
    text-align: justify;
    max-height: 200px;
}

.story_content_link {
    margin-left: 10px;
    font-size: 19px;
}

@media (min-width: 639px) {
    .constraint_height_story_square {
        height: 270px;
    }

    .story_list_photo {
        margin-bottom: 30px !important;
    }

    .storySummary {
        margin-bottom: 0px;
    }
}

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
}

.header-container {
  /*display: inline-block;
  position: relative;*/
}

.img-container {
    margin-top: 20px !important;
}

.nav-img {
  min-height: 80px;
  max-height: 110px;
  width: 100%;
}

.header-img {
  position: relative;
}

.category-img {
    position: relative;
    margin-bottom: 20px;
    border-radius: 10px;
    /*height: 280px;*/
}

.category-img:hover {
    cursor: pointer;
    opacity: 0.7;
}

.header-play-img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.header-play-img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.story_headline {
    margin-top: 10px;
    display: table;
    color: var(--dark-gray-text-color);
    margin-left: 20px;
    font-size: 20px;
}

.story_photo {
    /* display: table; */
    position: relative;
    width: 100%;
    height: 220px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}

.story_photo_play {
    position: relative;
    width: 90%;
    height: 500px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
}

.story_photo_play:hover {
    cursor: pointer;
    opacity: 0.6;
}

.play_gumb_story {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto;
    height: 120px;
    height: 120px;
}

.story_title {
    margin-top: 10px;
    /*color: white;*/
    color: #324A58;
    margin-left: 20px;
    font-size: 16px;
    margin-bottom: 0px !important;
    text-align: center;
    font-weight: 600;
}

.story_author {
    margin-top: 10px;
    display: table;
    color: black;
    margin-left: 20px;
    font-size: 10px;
}

.play_story_author {
    margin-top: 15px;
    font-weight: 600;
    /*color: white;*/
    color: #324A58;
    font-size: 18px;
}

.play_story_reader {
    margin-top: 15px;
    /*color: white;*/
    color: #324A58;
    font-size: 15px;
}

.advertisment_container {
    background-color: rgb(172, 172, 172, 0.7);
    border-radius: 10px;
    padding-top: 1px;
    padding-bottom: 20px;
    margin-left: 10%;
    margin-right: 10%;
}

.advertisment_container:hover {
  cursor: pointer;
  opacity: 0.5;
}

.advertisment_image {
    height: 296px;
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.advertisment_title {
    margin-top: 40px;
    margin-bottom: 0px !important;
    font-size: 25px;
    /* color: white !important; */
    color: #324A58 !important;
    text-align: center;
    font-weight: 600;
}

.advertisment_text {
    margin-top: 20px;
    font-size: 17px;
    text-align: center;
    color: black !important;
}

.admvertisment_image {
  margin-top: 30px;
  width: 40px;
}

.my_grid {
  margin-left: 7% !important;
}

.advertisment_text_price {
  font-size: 24px;
  text-align: right;
  color: black !important;
}

.button_subscribe {
    margin-top: 30px;
    margin-left: 60px;
    border-radius: 10px;
    color: white;
    border: none;
    padding: 15px 10px 15px 10px;
    background-color: var(--orange-color);
}

.button_subscribe:hover {
    opacity: 0.7;
}

.footer_head_text {
    color: white;
    margin-bottom: 0px !important;
    margin-top: 15px;
    margin-left: 20%;
    font-size: 20px;
}

.footer_sub_text {
    color: white;
    margin-bottom: 0px !important;
    margin-top: 0px;
    font-size: 15px;
    margin-left: 20%;
    text-decoration: none;
}

.footer_sub_text_no_underscore {
    color: white;
    margin-bottom: 0px !important;
    margin-top: 0px;
    font-size: 15px;
    margin-left: 20%;
    text-decoration: none;
}

.footer_sub_text:hover {
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

.footer_down_box {
    padding-bottom: 15px;
}

.footer_button_text {
    margin-top: 20px;
    margin-left: 5%;
    margin-bottom: 0px !important;
    /*color: white;*/
    color: #324A58 !important;
    font-size: 15px;
}

.footer_button_folow_text {
    margin-top: 8px;
    margin-left: 5%;
    margin-bottom: 0px !important;
    /*color: white;*/
    color: #324A58 !important;
    font-size: 15px;
}

.share_icons_left_margin {
    margin-left: 5%;
}

.footer_share_icons {
    width: 20px;
    height: 20px;
}




/* from internet */

button {
    background: none;
    border: none;
    cursor: pointer;
}

input[type="range"] {
    height: 5px;
    -webkit-appearance: none;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    background: #3b7677;
    transition: background 0.2s ease;
    cursor: pointer;
}

.audio-player {
    padding: 12px;
    /* border-radius: 5px; */
    box-shadow: 0 28px 28px rgb(0 0 0 / 20%);
    margin: auto;
    /* color: var(--white); */
    background-color: black;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 100px; */
}

.imagePlayer {
    max-width: 80px;
    /*height: 130px;*/
    max-height: 80px;
    border-radius: 2px;
}

.imagePlaylistMain {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 2px;
    margin-left: 10px;
    /*margin-top: 10px;
    margin-bottom: 10px;*/
}

.imagePlaylistOther {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 2px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.player_title_story {
    font-size: 20px;
    color: white;
    text-align: left;
}

.player_author_story {
    font-size: 10px;
    color: white;
    text-align: left;
}

.artwork {
    border-radius: 120px;
    display: block;
    margin: auto;
    height: 150px;
    width: 200px;
}

.track-info {
    text-align: center;
    z-index: 1;
    position: relative;
}

.zeroTopMargin {
    margin-top: 0 !important;
    /* height: 90px; */
}

.title {
    font-weight: 700;
    margin-bottom: 4px;
}

.svgControls {
    width: 48px !important;
    height: 48px !important;
}

.svgControlsSide {
    width: 44px !important;
    height: 44px !important;
}

.artist {
    font-weight: 300;
    margin-top: 0;
}

.storyRecTitle {
    color: white;
    font-size: 20px;
    font-weight: bold;
    /*margin-left: 5px;*/
    margin-bottom: 0px !important;
    margin-top: 0px;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.textLeft {
    text-align: left;
}

.textCenter {
    text-align: center;
}

.storyPlaylistTitle {
    /*color: white;*/
    color: #324A58;
    font-size: 27px;
    margin-left: 5px;
    margin-bottom: 0px !important;
    margin-top: 35px;
    font-weight: 600;
}

.storyPlaylistTitleMobile {
    /*color: white;*/
    color: #324A58;
    font-size: 20px;
    margin-left: 10px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-weight: 600;
}

.storyPlaylistTitleOther {
    /*color: white;*/
    color: #324A58;
    font-size: 20px;
    /*margin-left: 5px;*/
    margin-bottom: 0px !important;
    margin-top: 30px;
    font-weight: 600;
}

.storyPlaylistTitleAlert {
    /*color: white;*/
    color: #324A58;
    font-size: 20px;
    /*margin-left: 5px;*/
    margin-bottom: 0px !important;
    margin-top: 5px;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 600;
}

.storyRecAuthor {
    color: white;
    font-size: 12px;
    text-align: left;
    /*margin-left: 5px;*/
    margin-bottom: 0px !important;
}

.storyPlaylistAuthorOther {
    /*color: white;*/
    color: #324A58;
    font-size: 11px;
    margin-bottom: 4px !important;
}

.storyPlaylistAuthor {
    /*color: white;*/
    color: #324A58;
    font-size: 15px;
    margin-left: 23px;
    margin-bottom: 4px !important;
}

.storyPlaylistAuthorMobile {
    /*color: white;*/
    color: #324A58;
    font-size: 10px;
    margin-left: 10px;
    margin-bottom: 4px !important;
    margin-top: 10px !important;
}

.storyDuration {
    /*color: white;*/
    color: #324A58;
    font-size: 20px;
    margin-top: 55px;
    margin-right: 20px;
    text-align: right;
}

.storyDurationOther {
    /*color: white;*/
    color: #324A58;
    font-size: 17px;
    margin-top: 40px;
    margin-right: 20px;
    text-align: right;
}

.like_unlike_story {
    margin-top: 35px;
    width: 70px;
    height: 70px;
}

.like_unlike_story_other {
    margin-top: 23px;
    width: 60px;
    height: 60px;
}

.marginRightSmallBtn {
    margin-right: 5px;
}

.invisible {
    
}

.control_btn {
    margin-top: 5px;
    width: 20% !important;
    height: 20% !important;
}

.menu_btn {
    width: 18% !important;
    height: 18% !important;
}

@media (min-width: 400px) {
    .menu_btn {
        width: 48px !important;
        height: 48px !important;
    }
}

@media (min-width: 400px) {
    .control_btn {
        width: 57px !important;
        height: 57px !important;
    }
}

.audio-controls {
    /*display: flex;
    justify-content: center;*/
    width: 100% !important;
    margin: 0 auto 0px;
    margin-left: -25px !important;
}

@media (min-width: 400px) {
    .audio-controls {
        width: 75%;
    }
}

@media (min-width: 300px) {
    .audio-controls {
        margin-left: auto !important; 
    }
}

.audio-controls path {
    fill: var(--white);
}

.color-backdrop {
    /* background: linear-gradient(45deg, var(--orange-color) 10%, transparent 100%) no-repeat; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

    .color-backdrop.playing {
        /* animation: colorChange 20s alternate infinite; */
    }

@keyframes colorChange {
    from {
        filter: hue-rotate(0deg);
    }

    to {
        filter: hue-rotate(360deg);
    }
}

.pointer_on_hoover:hover {
    cursor: pointer;
}

.searchBar-mini-menu {
    padding-right: 30px;
    padding-top: 14px;
}

.termsConditionsMarginTop {
    margin-top: 50px;
}

/** styles prejsnja **/

:root {
    --navBg: #060b26;
  }
  

.navbar {
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    color: black;
  }

  .show-hide-menu {
    margin-bottom: 50px;
  }
  
  .nav-menu {
    /*background-color: var(--navBg);*/
    background-color: rgba(46, 38, 17, 1.0);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 50;
  }

  .navbar-menu {
    margin-bottom: 50px;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
    font-weight: 400;
    color: rgb(189, 146, 93) !important;
  }
  
  .nav-text a {
    text-decoration: none;
    color: rgb(189, 146, 93) !important;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    /*background-color: #1a83ff;*/
    background-color: rgba(255, 255, 255, 0.3);
    color: rgb(189, 146, 93) !important;
  }
  
  .nav-menu-items {
    width: 100%;
    padding: 0;
  }
  
  .navbar-toggle {
    /*background-color: var(--navBg);*/
    /*background-color: rgba(0, 0, 0, 0.8);*/
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }

  .playerTitleContainer {
    position: relative;
  }

  .playerTextInnerContainer {
    position: absolute;
    bottom: 0;
  }


  .mom {
    width: 100%; /* Try setting this to 400px or something */
    display: table;
}
.child {
    display: table-cell;
    color: white;
}

.childTime {
    width: 50px;
}

.childinner {
    margin-left: 0px;
    height: 10px;
    /* Decorative .. */
}
.child:first-child .childinner {
    margin-left: 0;
}



/* PRENOVA SPLETNA STRAN */


.footer_text {
    /*font-family: 'Yeseva One', cursive !important;*/
    font-family: 'Quicksand', sans-serif !important;
    color: var(--less-orange-color);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }

.footer_box {
    background-color: var(--orange-color);
    /*margin: 3%;*/
    height: 45px;
    margin-bottom: 3% !important;
}


.nav_link_margin {
    margin-top: 0px !important;
    display: inline-block;
    /*width: 16%;*/
    margin-right: 5%;
}

.navigation_menu {
    color: rgb(189, 146, 93) !important;
    /*margin-left: 3% !important;*/
    display: inline-block;
    width: 100%;
}

.nav-header {
    height: 100px;
    margin-top: 3%;
    /*margin-left: 3%;
    margin-right: 3%;*/
    background-color: rgb(189, 146, 93);
    border-top-right-radius: 150px;
}

.nav-header-main {
    margin-top: 3%;
    /*margin-right: 3%;*/
    /*background-color: rgb(189, 146, 93);
    border-top-right-radius: 150px;*/
  
    /*background-image: url('assets/slika_dom_osnovna.png');*/
    background-image: url('assets/Naslovna_slika.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: right;
    width: 100%;
    aspect-ratio: 2.5 / 1;
}

.nav-link-header {
    text-decoration: none !important;
}

.nav-link-header:hover {
    text-decoration: none !important;
}

.left_nav_buttons {
    padding-left: 3% !important;
}

.active_link {
    color: var(--blue-color) !important;
}

.nav_link {
    color: var(--orange-color);
    text-decoration: none;
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    display: inline-block;
}

.nav_link:hover {
    color: var(--orange-color);
    text-decoration: none !important;
}

.app_title {
    color: var(--less-orange-color);
    font-size: 50px;
    font-weight: 400;
    text-decoration: none !important;
    margin-top: 20px;
    /*font-family: 'Quicksand', sans-serif;*/
    font-family: 'Yeseva One', cursive;
    white-space: nowrap;
    width: 85%;
    overflow: hidden;
}

@media only screen and (max-width: 639px) {
    .app_title_longer {
        font-size: 35px !important;
        margin-top: 40px;
    }
}

.margin_top_headline {
    /*position: absolute;
    left: 5%;*/
    margin-left: 25px;
    padding-top: 15px;
}

svg {
    color: var(--orange-color) !important;
}

.kontakt-box {
    margin-left: -20px !important;
}

@media (min-width: 1200px) {
    .kontakt-box {
        margin-left: -30px !important;
    }
}

.kontakt-text {
    margin-top: 30px !important;
    color: var(--orange-color);
    text-align: left;
}

.ravnatelj_card {
    margin-top: 70px !important;
    background-color: var(--blue-color);
    height: 200px;
    border-top-right-radius: 100px;
    width: 58% !important;
    margin-right: 2% !important;
    padding-left: 10px !important;
}

.marinko_card {
    margin-top: 70px !important;
    background-color: var(--less-orange-color);
    height: 200px;
    border-top-left-radius: 100px;
    width: 38% !important;
    margin-left: 2% !important;
}

.ravnatelj_headline_text {
    color: white;
    margin-left: 0px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    font-size: 25px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ravnatelj_info_text {
    color: white;
    margin-left: 0px !important;
    font-size: 15px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.marinko_headline_text {
    margin-right: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-align: right;
    color: var(--orange-color);
    font-size: 25px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.marinko_info_text {
    color: white;
    margin-right: 10px !important;
    font-size: 15px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    color: var(--orange-color);
}

@media only screen and (max-width: 600px) {
    .ravnatelj_info_text {
        margin-left: 0% !important;
    }

    .marinko_info_text {
        margin-right: 0% !important;
    }

    .ravnatelj_headline_text {
        margin-left: 0% !important;
        margin-top: 35px !important;
    }

    .marinko_headline_text {
        margin-right: 0% !important;
        margin-top: 35px !important;
    }
}

@media only screen and (max-width: 455px) {
    .ravnatelj_info_text {
        font-size: 10px !important;
    }

    .marinko_info_text {
        font-size: 10px !important;
    }

    .ravnatelj_headline_text {
        font-size: 15px !important;
    }

    .marinko_headline_text {
        font-size: 15px !important;
    }
}


.osnovna_stran_zgoraj {
    position: relative;
    height: 180px;
    z-index: 5;
}

.o_nas {
    position: absolute;
    left: 0px;
    height: 130px;
    right: 0px;
    background-color: var(--less-orange-color);
}

.o_nas_text {
    color: var(--orange-color);
    font-size: 50px;
    font-weight: 400;
    text-decoration: none !important;
    margin-top: 25px;
    margin-left: 30px;
    /*font-family: 'Quicksand', sans-serif;*/
    font-family: 'Yeseva One', cursive;
    text-align: left;
    z-index: 3;
}

.procelje_doma_img {
    background-image: url('assets/procelje_dom_2.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 600px;
    width: 60%;
    max-width: 700px;
    position: absolute;
    right: 0px;
    top: 30px;
    z-index: 5;
}

.nagovor_ravnatelj {
    margin-right: 30px;
    background-color: rgb(189, 146, 93);
    border-top-right-radius: 250px;
    z-index: 40;
    position:relative;
}

@media only screen and (max-width: 639px) {
    .nagovor_ravnatelj {
        margin-right: 0px;
    }
}

.nagovor_ravnatelj_naslov {
    color: white;
    width: 70%;
    margin-left: 4% !important;
    margin-bottom: 60px !important;
    font-size: 20px !important;
    font-weight: 800;
}

/*.ravnatelj_text_box {
  
    height: fit-content;
    min-height: 100px;
    width:  300px;

    position: relative;
    border-top-right-radius: 200px;
  }
  .ravnatelj_text_box i, .ravnatelj_text_box::before {
    content: '';
    float: right;
    height:100%;
    width: 50%;
    shape-outside: radial-gradient(farthest-side at left, transparent calc(100% - 5px), #fff 0);
  }*/

.nagovor_ravnatelj_text {

    text-align: justify;
    color: white;
    margin-left: 4% !important;
    margin-bottom: 40px !important;
    margin-right: 5%;
    font-size: 18px !important;
    font-weight: 100;
    display: block;
    width: fit-content;
}

.nagovor_ravnatelj_text::first-line {
    word-spacing: 1px
}

/*.box {
  
    height: 1000px;
    width:  700px;

    position: relative;
    border-top-right-radius: 200px;
  }
  .box i, .box::before {
    content: '';
    float: right;
    height:100%;
    width: 50%;
    shape-outside: radial-gradient(farthest-side at left, transparent calc(100% - 5px), #fff 0);
  }*/
  /*.box i {
    float: right;
    shape-outside: radial-gradient(farthest-side at left,  transparent calc(100% - var(--p)), #fff 0);
  }*/








.nagovor_ravnatelj_podpis {
    color: white;
    text-align: right;
    margin-right: 5%;
    font-size: 18px !important;
    font-weight: 100;
}

.osnovna_stran_svetniki_in_duhovniki {
    width: 100%;
    margin-top: 50px;
    position:relative;
}

.svetniki_in_duhovniki_podlaga {
    background-color: var(--less-orange-color);
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    z-index: 1;
}

@media only screen and (max-width: 639px) {
    .svetniki_in_duhovniki_podlaga {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }
}

.vincencij_card {
    background-color: var(--blue-color);
    /*height: 200px;*/
    border-bottom-right-radius: 100px;
    z-index: 10;
    /* margin-left: 30px !important; */
}

.no_margin_left {
    margin-left: 0px !important;
}

.uk-grid> {
    margin-bottom: inherit !important;
}

.vincencij_text {
    text-align: justify;
    color: white;
    font-size: 18px !important;
    font-weight: 100;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 40px !important;
    margin-bottom: 4%;
    margin-left: 10px;
}

.vincencij_slika {
    background-image: url('assets/Vincencij.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 140%;
    height: 100%;
    background-position: center bottom;
}

@media only screen and (max-width: 1464px) {
    .vincencij_slika {
        background-size: 100% auto;
        width: 140%;
        height: 100%;
    }
}

.vincencij_text_right {
    z-index: 10;
}

.vincencij_headline {
    margin-top: 60px;
    margin-left: 10px;
    color: var(--orange-color);
    font-size: 30px;
    font-weight: 400;
    text-decoration: none !important;
    /*font-family: 'Quicksand', sans-serif;*/
    font-family: 'Yeseva One', cursive;
    width: 80%;
}

@media only screen and (max-width: 639px) {
    .vincencij_headline {
        margin-top: 10px !important;
    }
}

@media only screen and (max-width: 770px) {
    .vincencij_headline {
        margin-top: 30px !important;
    }
}


.vincencij_right_text {
    margin-right: 30px;
    margin-left: 10px;
    color: var(--orange-color);
    font-size: 15px;
    font-weight: 100;
}

.vincencij_right_text_italic {
    margin-right: 30px;
    margin-left: 10px;
    color: var(--orange-color);
    font-size: 15px;
    font-weight: 100;
    font-style: italic;
}

.gnidovec_card {
    background-color: var(--blue-color);
    /*height: 200px;*/
    border-top-left-radius: 100px;
    z-index: 10;
    padding-top: 20px !important;
}

@media only screen and (max-width: 639px) {
    .gnidovec_card {
        margin-left: 30px!important;
    }
}

.gnidovec_slika {
    background-image: url('assets/gnidovec.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: left bottom;
}

@media only screen and (max-width: 1464px) {
    .gnidovec_slika {
        background-size: 100% auto;
    }
}

.gnidovec_text {
    text-align: justify;
    color: white;
    font-size: 18px !important;
    font-weight: 100;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4%;
    margin-left: 20px;
    margin-right: 40px;
}

.no_padding_left {
    padding-left: 0px !important;
}

.gnidavec_headline {
    margin-top: 40px;
    margin-left: 70px;
    color: var(--orange-color);
    font-size: 30px;
    font-weight: 400;
    text-decoration: none !important;
    /*font-family: 'Quicksand', sans-serif;*/
    font-family: 'Yeseva One', cursive;
}

@media only screen and (max-width: 810px) {
    .gnidavec_headline {
        margin-top: 0px;
    }
}

.orange_box_zgoraj {
    margin-top: 10px;
    width: 100%;
    height: 150px;
    background-color: var(--less-orange-color);
    border-top-left-radius: 80px;
}

.groups_orange_box_zgoraj {
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    background-color: var(--less-orange-color);
    border-top-right-radius: 80px;
}

.groups_darker_box_zgoraj {
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    background-color: var(--blue-color);
    border-top-left-radius: 80px;
}

.sprejem_v_dom_header {
    font-weight: 800;
    font-size: 18px;
    color: var(--blue-color);
    margin-left: 3px;
}

.sprejem_v_dom_text {
    padding-top: 10px !important;
    font-size: 18px;
    color: var(--blue-color);
    margin-left: 3px;
}

.sprejem_v_dom_text_down {
    margin-top: 30px;
    font-size: 18px;
    color: var(--orange-color);
    margin-left: 3px;
}

.inside_text_right_boxes {
    padding-top: 45px;
    padding-left: 30px;
    font-size: 18px;
    color: var(--orange-color)
}

.orange_box_novica_group {
    margin-top: 10px;
    width: 100%;
    min-height: 160px;
    background-color: var(--less-orange-color);
    border-top-right-radius: 80px;
}

.orange_box_novica {
    margin-top: 10px;
    width: 100%;
    background-color: var(--less-orange-color);
    border-top-right-radius: 80px;
}

.inside_title_dogodek_group {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    margin-bottom: 0px !important;
    color: var(--orange-color)
}

.inside_datum_dogodek_group {
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 15px;
    margin-bottom: 5px !important;
    color: var(--orange-color)
}

.inside_text_dogodek_group {
    margin-top: 0px !important;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    color: var(--orange-color)
}

.datum_dogodek_group {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 15px;
    color: var(--orange-color)
}

.inside_text_dogodek {
    padding-bottom: 30px;
    margin-top: 0px !important;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    color: var(--orange-color)
}

.slika_dogodka_container {
    padding-top: 35px !important;
}

.slika_group_novice {
    /*border: 1px solid var(--orange-color);*/
    margin-top: 30px;
    padding-bottom: -2px;
    padding-top: -2px;
    aspect-ratio: 1.35 / 1;
}

.slika_group_novice_tag {
    width: 100%;
    height: 100%;
}

.dogodek_group_element {
    padding: 10px;
}

.dogodek_group_element:hover {
    cursor: pointer;
    /*opacity: 0.8;*/
}

.inside_text_right_boxes_heading {
    padding-top: 15px;
    padding-left: 20px;
    font-size: 20px;
    color: var(--orange-color);
    text-transform: capitalize;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.inside_text_darker_boxes_heading {
    padding-top: 15px;
    padding-right: 20px;
    text-align: right;
    font-size: 20px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.inside_text_right_boxes_izvajalec {
    padding-left: 20px;
    font-size: 15px;
    color: var(--orange-color);
}

.inside_text_darker_boxes_izvajalec {
    padding-right: 20px;
    text-align: right;
    font-size: 15px;
    color: #ffffff;
}

.inside_text_right_boxes_kdaj_kje_dodatno_link {
    padding-left: 20px;
    font-size: 15px;
    color: var(--orange-color);
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    text-decoration: none !important;
}

.inside_text_right_boxes_kdaj_kje_dodatno_link:hover {
    color: var(--orange-color) !important;
}

.inside_text_darker_boxes_kdaj_kje_dodatno_link {
    padding-right: 20px;
    text-align: right;
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    text-decoration: none !important;
}

.inside_text_darker_boxes_kdaj_kje_dodatno_link:hover {
    color: #ffffff !important;
}

.groups_darker_box_margin_zgoraj {
    padding-top: 10px !important;
}

.inside_text_darker_boxes_pril_sk_text {
    padding-right: 20px;
    text-align: right;
    font-size: 20px;
    /*font-weight: bold;*/
    color: #ffffff;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    text-decoration: none !important;
}

.orange_box_sredina {
    margin-top: 30px;
    width: 100%;
    height: 150px;
    background-color: var(--less-orange-color);
}

.groups_orange_box_sredina {
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    background-color: var(--less-orange-color);
}

.groups_darker_box_sredina {
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    background-color: var(--blue-color);
}

.inside_text_middel_boxes {
    padding-top: 45px;
    padding-left: 30px;
    font-size: 18px;
    color: var(--orange-color)
}

.orange_box_spodaj {
    margin-top: 30px;
    width: 100%;
    height: 150px;
    background-color: var(--less-orange-color);
    border-bottom-left-radius: 80px;
}

.groups_orange_box_spodaj {
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    background-color: var(--less-orange-color);
    border-bottom-right-radius: 80px;
}

.groups_darker_box_spodaj {
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    background-color: var(--blue-color);
    border-bottom-left-radius: 80px;
}

.groups_orange_box_margin_spodaj {
    padding-bottom: 15px;
}

.inside_text_down_boxes {
    padding-top: 50px;
    padding-left: 30px;
    font-size: 18px;
    color: var(--orange-color);
    cursor: pointer;
    font-weight: 800;
}

.inside_text_down_boxes:hover {
    text-decoration: underline;
}

.inside_href_text_down_boxes {
    color: var(--orange-color) !important;
}

.inside_href_text_down_boxes:hover {
    color: var(--orange-color) !important;
    text-decoration: underline;
}

.inside_href_text_upper_boxes {
    margin-top: 30px;
    margin-left: 30px;
    color: var(--orange-color) !important;
}

.inside_href_text_upper_boxes:hover {
    color: var(--orange-color) !important;
    text-decoration: underline;
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .spinner {
    border: 4px solid var(--orange-color);
    /*border-left-color: #09f;*/
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: relative !important;
  }


/*@media only screen and (max-width: 2700px) {
    .nav-header-main {
        height: 1000px; 
    }
}

@media only screen and (max-width: 2200px) {
    .nav-header-main {
        height: 800px; 
    }
}

@media only screen and (max-width: 1500px) {
    .nav-header-main {
        height: 600px; 
    }
}


@media only screen and (max-width: 1100px) {
    .nav-header-main {
        height: 500px; 
    }
}


@media only screen and (max-width: 800px) {
    .nav-header-main {
        height: 350px; 
    }
}

@media only screen and (max-width: 700px) {
    .nav-header-main {
        height: 310px; 
    }
}

@media only screen and (max-width: 600px) {
    .nav-header-main {
        height: 270px; 
    }
}

@media only screen and (max-width: 500px) {
    .nav-header-main {
        height: 230px; 
    }
}

@media only screen and (max-width: 400px) {
    .nav-header-main {
        height: 170px; 
    }
}

@media only screen and (max-width: 300px) {
    .nav-header-main {
        height: 130px; 
    }
}*/

